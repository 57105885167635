import { React, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const GerarPropostaParceiro = () => {
    const [nome, setNome] = useState('');
    const [contaEnergia, setContaEnergia] = useState([null]); // Updated to an array
    const [message, setMessage] = useState('');
    const [statusAlerta, setStatusAlerta] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    const handleFileChange = (index, file) => {
        const updatedFiles = [...contaEnergia];
        updatedFiles[index] = file;
        setContaEnergia(updatedFiles);
    };

    const addFileInput = () => {
        setContaEnergia([...contaEnergia, null]);
    };

    const removeFileInput = (index) => {
        const updatedFiles = [...contaEnergia];
        updatedFiles.splice(index, 1);
        setContaEnergia(updatedFiles);
    };

    async function submitGerarPropostaParceiro(e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = new FormData(); // Use FormData to handle file uploads
        body.append('nome', nome);
        contaEnergia.forEach((file, index) => {
            if (file) body.append(`contaEnergia[${index}]`, file);
        });

        const response = await fetchFlask('gerarPropostaParceiro', body);
        setMessage(response.message);
        setStatusAlerta(response.status);
        setOpenAlerta(true);

        if (response.status === 'success') {
            window.open(response.url, '_blank');

            setNome('');
            setContaEnergia([]);
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={statusAlerta}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Gerar Proposta Parceiros
            </Typography>

            <form action='' method='POST' id='formGerarPropostaParceiro' onSubmit={submitGerarPropostaParceiro}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <TextField
                        required
                        label='Nome do cliente'
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <Typography>Contas de Energia</Typography>
                    {contaEnergia.map((_, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <TextField
                                required
                                type='file'
                                size='small'
                                inputProps={{ accept: 'application/pdf' }}
                                onChange={(e) => handleFileChange(index, e.target.files[0])}
                            />
                            <IconButton
                                sx={{ marginLeft: '10px' }}
                                onClick={() => removeFileInput(index)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    ))}
                    <IconButton onClick={addFileInput} sx={{ backgroundColor: 'lightgrey' }}>
                        <AddIcon sx={{ color: '#13154F' }} fontSize='large'/>
                    </IconButton>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Gerar Proposta</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default GerarPropostaParceiro;
