import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../components/protectedRoutes';
import GerarProposta from '../pages/comercial/gerarProposta';
import GerarPropostaParceiro from '../pages/comercial/gerarPropostaParceiro';

const RouteConsultor = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='Consultor'/>}>
                <Route path='gerarProposta' element={<GerarProposta consultor="True"/>}/>
                <Route path='gerarPropostaParceiro' element={<GerarPropostaParceiro/>}/>
            </Route>
        </Routes>
    );
};

export default RouteConsultor;
