import { React } from 'react';

import { Route, Routes } from 'react-router-dom';

import AtualizarProjeto from '../pages/settaServicos/atualizarProjeto';
import InserirJustificativa from '../pages/settaServicos/inserirJustificativa';
import ProtectedRoute from '../components/protectedRoutes';

const RouteSettaServicos = () => {
    return (
        <Routes>
            <Route element={<ProtectedRoute role='SS'/>}>
                <Route path='atualizarProjeto' element={<AtualizarProjeto/>}/>
                <Route path='inserirJustificativa' element={<InserirJustificativa/>}/>
            </Route>
        </Routes>
    );
};

export default RouteSettaServicos;
