import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';

const AtualizarProjeto = () => {
    const [listProjetos, setListProjetos] = useState([]);
    const [projeto, setProjeto] = useState('');
    const [message, setMessage] = useState('');
    const [statusAlerta, setStatusAlerta] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        async function pegarDados () {
            const response = await fetchFlask('listarProjetos');
            if (response.status !== 'error') {
                setListProjetos(response.project_list);
            } else {
                setMessage(response.message);
                setStatusAlerta(response.status);
                setOpenAlerta(true);
            }
        };
        pegarDados();
    }, []);

    async function submitAtualizarProjeto (e) {
        e.preventDefault();
        setIsSubmit(true);

        const body = JSON.stringify({
            projeto
        });
        const response = await fetchFlask('atualizarProjeto', body);
        setMessage(response.message);
        setStatusAlerta(response.status);
        setOpenAlerta(true);
        if (response.status === 'success') {
            setProjeto('');
        }
        setIsSubmit(false);
    }

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={statusAlerta}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Atualizar Projeto
            </Typography>

            <form action='' method='POST' id='formAtualizarProjeto' onSubmit={submitAtualizarProjeto}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelProjeto'>Projeto</InputLabel>
                    <Select
                        id='projeto'
                        labelId='labelProjeto'
                        label='Projeto'
                        sx={{ width: '300px' }}
                        value={projeto}
                        onChange={(e) => setProjeto(e.target.value)}
                        required
                    >
                        {listProjetos.map((item) => (
                            <MenuItem key={item[0]} value={item[0]}>{item[1]}</MenuItem>
                        ))}
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Atualizar Projeto</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default AtualizarProjeto;
