import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { fetchFlask } from '../../utils/fetchFlask';
import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { formatCurrency } from '../../utils/stringFormat';

const CobrarMulta = () => {
    const [dados, setDados] = useState([]);
    const [cliente, setCliente] = useState('');
    const [listaClientes, setListaCLientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [valorMulta, setValorMulta] = useState(0);
    const [distrato, setDistrato] = useState(false);
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        async function buscarContatos () {
            const response = await fetchFlask('buscarClientesContato');
            if (response.status !== 'error') {
                const dadosFetch = response.dados;
                setDados(dadosFetch);
                const dadosClientes = [...new Set(dadosFetch.map(item => item[0]))];
                setListaCLientes(dadosClientes);
            } else {
                handleAlerta(response);
            }
        };
        buscarContatos();
    }, []);

    const selectCliente = (e) => {
        setCliente(e.target.value);

        const dadosFiltrados = dados.filter(item => item[0] === e.target.value);
        const dadosUnidades = [...new Set(dadosFiltrados.map(item => item[2]))];

        const unidadesCliente = ['Selecionar Todas'];
        for (const unidade of dadosUnidades) {
            let nomeUnidade = unidade + ' | ';
            const ccsUnidade = dadosFiltrados.filter(item => item[2] === unidade).sort();
            for (const lista of ccsUnidade) {
                nomeUnidade = nomeUnidade + lista[3] + ' / ';
            }
            unidadesCliente.push(nomeUnidade.slice(0, -3));
        }
        setListaUnidades(unidadesCliente);
        setUnidades([]);
    };

    const selectUnidades = (e, values) => {
        if (values.includes('Selecionar Todas')) {
            let todasUnidades = [];
            todasUnidades = todasUnidades.concat(listaUnidades);
            todasUnidades.splice(0, 1);
            setUnidades(todasUnidades);
        } else {
            setUnidades(values);
        }
    };

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    async function submitCalcularMulta (e) {
        e.preventDefault();
        setIsSubmit(true);

        const unidadesFetch = [];
        for (const unidade of unidades) {
            unidadesFetch.push(unidade.split(' | ')[0]);
        }

        const body = JSON.stringify({
            unidades: unidadesFetch,
            nome: cliente,
            distrato
        });

        const response = await fetchFlask('calcularMulta', body);
        if (response.status === 'success') {
            setValorMulta(parseFloat(response.message));
            setOpenDialog(true);
            setIsSubmit(false);
            return;
        }
        handleAlerta(response);
        setIsSubmit(false);
    };

    async function submitCobrarMulta (e) {
        e.preventDefault();
        setIsSubmit(true);

        const unidadesFetch = [];
        for (const unidade of unidades) {
            unidadesFetch.push(unidade.split(' | ')[0]);
        }

        const body = JSON.stringify({
            valor: valorMulta,
            unidades: unidadesFetch,
            nome: cliente,
            distrato
        });

        const response = await fetchFlask('cobrarMulta', body);
        if (response.status === 'success') {
            setOpenDialog(false);
        }
        handleAlerta(response);
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>
                    Cobrar Multa
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`Valor calculado foi de: ${formatCurrency(valorMulta)}`}
                    </DialogContentText>
                    <DialogContentText>
                        Verifique se o valor está correto!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={isSubmit}
                        onClick={(e) => submitCobrarMulta(e)}
                        endIcon={isSubmit && <CircularProgress size={20} />}
                    >
                        Confirmar
                    </Button>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                </DialogActions>
            </Dialog>

            <Typography variant='h3'>Cobrar Multa</Typography>

            <form action='' method='POST' id='formAlterarDados' onSubmit={submitCalcularMulta} >
                <Box className='column'>
                    <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                        <InputLabel id='labelCliente'>Cliente</InputLabel>
                        <Select
                            id='selectCliente'
                            labelId='labelCliente'
                            label='Cliente'
                            value={cliente}
                            onChange={selectCliente}
                            sx={{ width: '300px' }}
                            required
                        >
                            {listaClientes.map((item) => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                        <Autocomplete
                            sx={{ width: '500px' }}
                            multiple
                            disableCloseOnSelect
                            id='unidades'
                            limitTags={8}
                            value={unidades}
                            options={listaUnidades}
                            onChange={selectUnidades}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Unidades"
                                required={unidades.length === 0}
                                />
                            )}
                        />
                    </FormControl>

                    <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                        <FormControlLabel control={
                                <Checkbox
                                    onChange={(e) => setDistrato(e.target.checked)}
                                />
                            }
                            label="Troca de titularidade"
                        />
                    </FormControl>

                    <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                        <SubmitButton isSubmit={isSubmit} >Calcular Multa</SubmitButton>
                    </FormControl>

                </Box>
            </form>
        </Box>
    );
};

export default CobrarMulta;
