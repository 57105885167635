import { React, useEffect, useState } from 'react';

import { InputLabel, MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { pt } from 'date-fns/locale';

import { Alerta } from '../../components/alerta';
import SubmitButton from '../../components/submitButton';
import { fetchFlask } from '../../utils/fetchFlask';

const Distrato = () => {
    const [isCliente, setIsCliente] = useState(true);
    const [dados, setDados] = useState([]);
    const [cliente, setCliente] = useState('');
    const [listaClientes, setListaClientes] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [listaUnidades, setListaUnidades] = useState([]);
    const [dateTimeDistrato, setDateTimeDistrato] = useState(null);
    const [errorDateDistrato, setErrorDateDistrato] = useState(false);
    const [motivo, setMotivo] = useState('');
    const [message, setMessage] = useState('');
    const [status, setStatus] = useState('');
    const [openAlerta, setOpenAlerta] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);

    useEffect(() => {
        async function getClientes () {
            const response = await fetchFlask('buscarClientesContato');
            if (response.status !== 'error') {
                setDados(response.dados);
                const clientsArray = response.clients
                .map(obj => {
                    const key = Object.keys(obj)[0];
                    const value = obj[key];
                    return { key, value };
                });
                setListaClientes(clientsArray);
            } else {
                handleAlerta(response);
            }
        };
        getClientes();
    }, []);

    function handleAlerta (response) {
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);
    }

    const selectCliente = (e) => {
        setCliente(e.target.value);
        const selectedItem = listaClientes.find((item) => item.value === e.target.value);

        const dadosFiltrados = dados.filter(item => item[0] === selectedItem.key);
        const dadosUnidades = [...new Set(dadosFiltrados.map(item => item[2]))];

        const unidadesCliente = [];
        for (const unidade of dadosUnidades) {
            let nomeUnidade = unidade + ' | ';
            const ccsUnidade = dadosFiltrados.filter(item => item[2] === unidade).sort();
            for (const lista of ccsUnidade) {
                nomeUnidade = nomeUnidade + lista[3] + ' / ';
            }
            unidadesCliente.push(nomeUnidade.slice(0, -3));
        }
        setListaUnidades(unidadesCliente);
        setUnidades([]);
    };

    async function submitDistrato (e) {
        e.preventDefault();
        setIsSubmit(true);

        let data = '';
        if (dateTimeDistrato) {
            setErrorDateDistrato(false);
            data = dateTimeDistrato.toISOString().slice(0, 10);
        } else {
            setErrorDateDistrato(true);
            return;
        }

        const unidadesFetch = [];
        if (!isCliente) {
            for (const unidade of unidades) {
                unidadesFetch.push(unidade.split(' | ')[0]);
            }
        }

        const body = JSON.stringify({
            unidades: unidadesFetch,
            cliente: isCliente ? cliente : '',
            data,
            motivo
        });
        const response = await fetchFlask('distrato', body);
        setMessage(response.message);
        setStatus(response.status);
        setOpenAlerta(true);

        if (response.status === 'success') {
            setCliente('');
            setDateTimeDistrato('');
            setMotivo('');
            setErrorDateDistrato(false);
            setUnidades([]);
        }
        setIsSubmit(false);
    };

    return (
        <Box className='mainBox'>
            <Alerta
                texto={message}
                status={status}
                open={openAlerta}
                handleClose={() => setOpenAlerta(false)}
            />
            <Typography variant='h3'>
                Distrato
            </Typography>

            <form action='' method='POST' id='formDistrato' onSubmit={submitDistrato}>
                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <FormControlLabel
                        label={isCliente ? 'Distrato Cliente Completo' : 'Unidades'}
                        control={<Switch
                            defaultChecked
                            value={isCliente}
                            onChange={(e) => setIsCliente(!isCliente)}
                        />}
                    />
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }} >
                    <InputLabel id='labelCliente'>Cliente</InputLabel>
                    <Select
                        id='selectCliente'
                        labelId='labelCliente'
                        label='Cliente'
                        value={cliente}
                        onChange={selectCliente}
                        sx={{ width: '300px' }}
                        required
                    >
                        {listaClientes.map((item) => (
                            <MenuItem key={item.value} value={item.value} >
                                {item.key}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>

                {!isCliente && (
                    <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                        <Autocomplete
                            sx={{ width: '500px' }}
                            multiple
                            disableCloseOnSelect
                            id='unidades'
                            limitTags={8}
                            value={unidades}
                            options={listaUnidades}
                            onChange={(e, values) => setUnidades(values)}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                label="Unidades"
                                required={unidades.length === 0}
                                />
                            )}
                        />
                    </FormControl>
                )}

                <FormControl sx={{ marginTop: '20px' }} >
                    <LocalizationProvider adapterLocale={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker id='data' label='Data Distrato'
                            minDate={new Date('2023-01-02')}
                            value={dateTimeDistrato}
                            onChange={(value) => setDateTimeDistrato(value)}
                            slotProps={{
                                openPickerButton: {
                                    color: 'primary'
                                }
                            }}
                        />
                        {errorDateDistrato && (
                            <Typography variant='caption' color='red'>
                                Por favor selecione uma data!
                            </Typography>
                        )}
                    </LocalizationProvider>
                </FormControl >

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <InputLabel id='labelCat'>Motivo</InputLabel>
                    <Select
                        id='motivo'
                        labelId='labelCat'
                        label='motivo'
                        sx={{ width: '300px' }}
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                        required
                    >
                        <MenuItem value='Inadimplência'>Inadimplência</MenuItem>
                        <MenuItem value='Entrega Imóvel'>Entrega Imóvel</MenuItem>
                        <MenuItem value='Insatisfação'>Insatisfação</MenuItem>
                        <MenuItem value='Geração Própria'>Geração Própria</MenuItem>
                        <MenuItem value='Não Informado'>Não Informado</MenuItem>
                        <MenuItem value='Vetado'>Vetado</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ display: 'block', marginTop: '20px' }}>
                    <SubmitButton isSubmit={isSubmit}>Distratar</SubmitButton>
                </FormControl>
            </form>
        </Box>
    );
};

export default Distrato;
